import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const today = moment();

export const PERIODS = {
  CURRENT_MONTH: {
    key: "CURRENT_MONTH",
    label: "Current Month", // ToDo: i18n
    period: [moment().startOf("month").format(dateFormat), today.format(dateFormat)],
  },
  CURRENT_YEAR: {
    label: "Current Year", // ToDo: i18n
    key: "CURRENT_YEAR",
    period: [moment().startOf("year").format(dateFormat), today.format(dateFormat)],
  },
  ONE_MONTH: {
    label: "1 Month", // ToDo: i18n
    key: "ONE_MONTH",
    period: [moment().subtract(1, "months").format(dateFormat), today.format(dateFormat)],
  },
  ONE_YEAR: {
    label: "1 Year", // ToDo: i18n
    key: "ONE_YEAR",
    period: [moment().subtract(1, "years").format(dateFormat), today.format(dateFormat)],
  },
  THREE_YEARS: {
    label: "3 Years", // ToDo: i18n
    key: "THREE_YEARS",
    period: [moment().subtract(3, "years").format(dateFormat), today.format(dateFormat)],
  },
  TEN_YEARS: {
    label: "10 Years", // ToDo: i18n
    key: "TEN_YEARS",
    period: [moment().subtract(10, "years").format(dateFormat), today.format(dateFormat)],
  },
};
export const DEFAULT_PERIOD = PERIODS.ONE_YEAR;
