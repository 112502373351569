import { DEFAULT_PERIOD } from "./../constants/seb-poc";
import { defineStore } from "pinia";

type TSebPeriod = {
  label: string;
  key: string;
  period: [string, string];
};
type TDemoStore = {
  _sebPeriod: TSebPeriod;
};

export const useDemoStore = defineStore("demoStore", {
  state: () =>
    ({
      _sebPeriod: DEFAULT_PERIOD,
    }) as TDemoStore,
  getters: {
    sebPeriod: (state) => state._sebPeriod,
  },
  actions: {
    // Mutators:
    setPeriod(sebPeriod: TSebPeriod) {
      this._sebPeriod = sebPeriod;
    },
  },
});

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
type AppStoreNoSGA = ReturnType<typeof useDemoStore>;
export type TAppStore = Omit<AppStoreNoSGA, keyof NullStore>;
